<template>
  <content-wrapper :title="labels.LBL_CHANGE_PASSWORD" back>
    <template slot="actions">
      <el-button
        size="mini"
        type="success"
        @click="submitForm('changePasswordForm')"
      >
        <i class="el-icon-check mr-1"></i>
        {{ labels.LBL_SAVE }}
      </el-button>
    </template>

    <el-col :span="8">
      <el-form
        ref="changePasswordForm"
        :model="changePassword"
        :rules="rules"
        label-position="top"
      >
        <el-form-item
          prop="current_password"
          :label="labels.LBL_CURRENT_PASSWORD"
        >
          <el-input
            v-model="changePassword.current_password"
            show-password
          ></el-input>
        </el-form-item>

        <el-form-item prop="new_password" :label="labels.LBL_NEW_PASSWORD">
          <el-input
            v-model="changePassword.new_password"
            show-password
          ></el-input>
        </el-form-item>

        <el-form-item
          prop="new_password_confirmation"
          :label="labels.LBL_CONFIRM_PASSWORD"
        >
          <el-input
            v-model="changePassword.new_password_confirmation"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
    </el-col>
  </content-wrapper>
</template>

<script>
  import { labels, rules } from "@/common";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper";

  export default {
    name: "ChangePassword",

    components: { ContentWrapper },

    data() {
      const confirmPassword = (rule, value, callback) => {
        if (value !== this.changePassword.new_password) {
          callback(new Error(labels.ER_PASSWORDS_MATCH));
        } else {
          callback();
        }
      };

      return {
        labels,
        changePassword: {
          current_password: "",
          new_password: "",
          new_password_confirmation: "",
        },
        rules: {
          ...rules,
          new_password_confirmation: [
            {
              required: true,
              message: labels.PH_CONFIRM_PASSWORD,
              trigger: "blur",
            },
            { validator: confirmPassword, trigger: "blur" },
          ],
        },
      };
    },

    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store.dispatch("auth/changePassword", this.changePassword);
          }
        });
      },
    },
  };
</script>

<style></style>
